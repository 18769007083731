import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private defaultConfig = {
    timeOut: 4000,
    toastClass: "alert",
    positionClass: "toast-top-center",
    enableHtml: false
  }

  constructor(private toast: ToastrService) { }

  success(text: string, title?: string, icon?: string) {
    var config = this.defaultConfig;
    config.toastClass = "alert alert-success mt-3";

    if(!title) {title = ""}

    if(icon) {
      var htmlIcon = '<span class="'+icon+'"></span>';
      text = htmlIcon + text;
      config.toastClass = "alert alert-success alert-with-icon mt-3";
      config.enableHtml = true;
    }

    this.toast.warning(text, title, config);
  }

  warning(text: string, title?: string, icon?: string) {
    var config = this.defaultConfig;
    config.toastClass = "alert alert-warning mt-3";

    if(!title) {title = ""}

    if(icon) {
      var htmlIcon = '<span class="'+icon+'"></span>';
      text = htmlIcon + text;
      config.toastClass = "alert alert-warning alert-with-icon mt-3";
      config.enableHtml = true;
    }

    this.toast.warning(text, title, config);
  }

  error(text: string, title?: string, icon?: string) {
    var config = this.defaultConfig;
    config.toastClass = "alert alert-danger mt-3";

    if(!title) {title = ""}

    if(icon) {
      var htmlIcon = '<span class="'+icon+'"></span>';
      text = htmlIcon + text;
      config.toastClass = "alert alert-danger alert-with-icon mt-3";
      config.enableHtml = true;
    }

    this.toast.error(text, title, config);
  }
}
